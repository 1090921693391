// Import local Material Icons font (Roboto is default for Android)
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('./material-icons.woff2') format('woff2');
}

// Import Bootstrap styling, minus the problematic print stylesheet
$enable-print-styles: false;
@import '~bootstrap/scss/bootstrap.scss';

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: #bababa;
  font-family: Roboto, 'Helvetica Neue', arial, sans-serif;
  font-weight: 400;
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

#app {
  max-width: 601px; // sized for Galaxy Tab Active2. revisit this.

  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
  margin: 0 auto;
  background-color: #fafafa;

  .page-container {
    flex-grow: 1;
    flex-shrink: 1;
    overflow-y: auto;
    max-width: inherit;
  }
}

.nav-tabs .nav-link {
  cursor: pointer;
  user-select: none;
  text-transform: capitalize;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 400;
}

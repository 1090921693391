.error,
.info {
  .header {
    background-color: #dc3545;
    color: #fff;

    button {
      color: #fff;
      opacity: 1;
      text-shadow: 0 1px 0 #333;
    }
  }
}

.info .header {
  background-color: #17a2b8;
}
